import {IUser} from "@plumeuk/shapeshift-types";

export type IEditUserRequest = Omit<IUser, "blocked" | "provider" | "confirmed" | "blocked" | "createdAt" | "updatedAt"> ;

export type IEditUserResponse = IEditUserRequest

export const TEditUserRequest: IEditUserRequest = {
	firstname: "",
	lastname: "",
	email: "",
	username: ""
}


export const isUserValid = (user: IUser): boolean => (!!user.lastname && !!user.firstname);