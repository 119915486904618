import {Box, BoxProps, Button} from "@mui/material";
import {FC, useEffect} from "react";
import {makeStyles} from "tss-react/mui";
import {useApi} from "@plumeuk/shapeshift-identity";


const useStyles = makeStyles()((theme) => ({
	vm: {
		height: "100%"
	},
	fullScreenBtn: {
		backgroundColor: "#04262f",
		color: "white",
		border: "none",
		borderRadius: "20px",
		padding: "10px 20px",
		cursor: "pointer",
		textDecoration: "none",
		"&:hover": {
			backgroundColor: "#02434d"
		}
	},
	btnContainer: {
		textAlign: "center",
		marginBottom: "20px"
	}
}));

type IProps = {
	courseSlug?: string
} & Omit<BoxProps, "children">;

export const VirtualMachine: FC<IProps> = ({className, courseSlug, ...props}) => {
	const {classes, cx} = useStyles();
	const [{data: jwt}, getToken] = useApi<any>();

	useEffect(() => {
		if(courseSlug)
			getToken(`/api/user/token/${courseSlug}`)
	}, [courseSlug])

	const vmUrl = process.env.REACT_APP_VM_BASE_URL;
	if(!vmUrl){
		// eslint-disable-next-line no-console
		console.error("REACT_APP_VM_BASE_URL not set!")
		return <>Something has gone wrong</>
	}

	return (
		<Box className={cx(classes.vm, className)} {...props}>
			<Box className={classes.btnContainer}>
				<a href={vmUrl + "/?jwt=" + jwt} target="_blank" className={classes.fullScreenBtn} rel="noreferrer">Coding Full Screen</a>
			</Box>
			{(jwt) && <iframe src={vmUrl + "/?jwt=" + jwt} width="100%" height="600px" frameBorder="0"></iframe>}
		</Box>
	);
};