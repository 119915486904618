import {LinearProgress} from "@mui/material";
import {getModule} from "@plumeuk/shapeshift-common/common";
import {FC, useContext, useEffect} from "react";
import {useNavigate, useParams} from "react-router-dom";
import {CurriculumContext} from "../../contexts/curriculumContext";

export const CourseLandingPage: FC = () => {
	const navigate = useNavigate();
	const {courseSlug} = useParams();
	const {curriculumState} = useContext(CurriculumContext);

	useEffect(() => {
		if(!curriculumState)
			return;

		if(curriculumState.percentCompleted === 100) {
			//redirect handled in curriculum context
			return;
		}

		const nextModule = getModule(curriculumState, undefined, true);
		if(nextModule)
			navigate(`/course/${courseSlug}/${nextModule?.type}/${nextModule?.slug}`, {replace: true});
		else
			navigate(`/course/${courseSlug}/something-went-wrong`, {replace: true});
	}, [curriculumState]);

	return (
		<LinearProgress />
	);
}
