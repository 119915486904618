import {CourseTable} from "@plumeuk/shapeshift-common/courses";
import {useNavigate} from "react-router-dom";
import {CenteredContainer} from "../../components/pageBase/centeredContainer";
import {PageBase} from "../../components/pageBase/pageBase";
import {makeStyles} from "tss-react/mui";

const useStyles = makeStyles()((theme) => ({
	sideMenu: {
		color: "black"
	},
	courseTable: {
		"& [class*='courseItem']": {
			"& [class*='paper']": {
				background: "white"
			},
			borderRadius: "8px",
			cursor: "pointer",
			"& [class*='percentCompleted']": {
				fontWeight: 600,
				textTransform: "uppercase"
			},
			"& button ": {
				"& svg":{
					display: "none"
				},
				padding: "18px"
			}
		},
		"& [class*='title']": {
			fontSize: "35px"
		},
		"& [class*='infoContainer'] h4": {
			fontSize: "32px",
			marginBottom: "8px"
		},
		"& [class*='infoContainer'] p": {
			fontWeight: 500,
			color: theme.palette.primary.main
		}
	}
}));


export const MyLearningPage: React.FC = () => {
	const navigate = useNavigate();
	const {classes} = useStyles();

	return (
		<PageBase contentWidth="1300px" disableSideBar>
			<CenteredContainer>
				<CourseTable
					className={classes.courseTable}
					sx={{paddingTop: "40px"}}
					onGoToCourseAction={courseId => {navigate("/course/" + courseId)}}
					onGoToCertificateAction={courseId => {navigate(`/course/${courseId}/complete`)}}
				/>
			</CenteredContainer>
		</PageBase>
	);
}