import {Box, Button, Typography} from "@mui/material";
import {CenterTile} from "@plumeuk/shapeshift-common/pageBase";
import {CourseCertificate} from "@plumeuk/shapeshift-common/course";
import {FC, useEffect} from "react";
import {useNavigate, useParams} from "react-router-dom";
import {makeStyles} from "tss-react/mui";
import WorkspacePremiumOutlinedIcon from "@mui/icons-material/WorkspacePremiumOutlined";
import {CourseCertificateReactPDFDocument} from "./CertificateReactPdfDocument";
import {FacebookIcon, FacebookShareButton, LinkedinIcon, LinkedinShareButton, TwitterShareButton, WhatsappIcon, WhatsappShareButton, XIcon} from "react-share";
import {useCourseCertificate} from "@plumeuk/shapeshift-common/hooks";
import {toolbarHeight} from "../../constants";
import Confetti from "react-confetti";

const useStyles = makeStyles()((theme) => ({
	centeredContainer: {
		[theme.breakpoints.down("sm")]: {
			paddingTop: "60px"

		},
		[theme.breakpoints.up("sm")]: {
			"& > button": {
				width: "190px",
				height: "52px"
			}
		},
		textAlign: "center"
	},
	tagLine: {
		color: "#2A2A2F",
		fontWeight: 400
	},
	dashBoardBtn: {},
	file: {
		textAlign: "left",
		margin: "30px 0",
		[theme.breakpoints.down("sm")]: {
			background: "#BCBCBD33",
			textAlign: "inherit",
			"& > div:first-child": {
				display: "none"
			},
			"& h6": {
				fontSize: "22px"
			},
			"& [class*='downloadButton']": {
				borderTop: "solid 2px #d1d1d180"
			}
		}
	},
	shareContainer: {
		paddingBottom: "40px",
		justifyContent: "center",
		display: "flex",
		margin: "auto",
		// border: "1px solid red",
		gap: "10px",
		"& > *": {
			height: "60px",
			textAlign: "center",
			borderRadius: "5px",
			padding: "15px",
			"& svg": {
				width: "40px",
				height: "40px"
			}
		}
	},
	shareLabel: {
		textAlign: "left",
		fontWeight: "600",
		margin: "10px 0 5px 5px"
	},
	shareItem: {
		transition: "transform 0.15s ease-in-out",
		"&:hover": {
			transform: "scale(1.04)"
		},
		textAlign: "center",
		display: "flex",
		gap: "5px",
		flexDirection: "column",
		"& p": {
			color: theme.palette.secondary.main,
			fontSize: "13px"
		}
	},
	shareLabelContainer: {
		gap: 0,
		color: theme.palette.secondary.main,
		"& svg": {
			width: "24px",
			height: "24px"
		}
	}
}));

export const CourseCertificatePage: FC = () => {
	const {classes} = useStyles();
	const {courseSlug} = useParams();
	const navigate = useNavigate();
	const {certificate, getCertificate} = useCourseCertificate(courseSlug)

	useEffect(() => {
		getCertificate(courseSlug)
	}, [courseSlug])

	const goToDashboardBtn = <Button onClick={() => navigate("/my-learning")} className={classes.dashBoardBtn}>Go to dashboard</Button>;

	const title = `I've just completed ${certificate?.printedTitle} and Athlyticz has presented me with a certificate!`
	const w = window.innerWidth;
	const h = window.innerHeight;

	const socialShareLink: undefined | string = ((certificate as any)?.socialShareLink);
	const socialShareTags: undefined | string[] = ((certificate as any)?.socialShareTags);

	const tags = (socialShareTags && socialShareTags.length > 0) ? socialShareTags : ["datascience", "data", "sports", "sportsanalytics", "rstats", "python" ]

	return (
		<>
			<CenterTile
				className={classes.centeredContainer}
				title="Congratulations"
				icon={<WorkspacePremiumOutlinedIcon />}
				buttons={[goToDashboardBtn]}
			>
				<Typography variant="h6" className={classes.tagLine}>
					You finished the course and you have been awarded with a certificate. You can download it from the block below.
				</Typography>
				<CourseCertificate data={certificate} componentOverrides={{document: (cert) => <CourseCertificateReactPDFDocument certificate={cert} />}} courseSlug={courseSlug} />

				{socialShareLink && <Box className={classes.shareContainer}>
					<Box className={classes.shareLabelContainer}>
						<Typography variant="h4" className={classes.shareLabel}>Share: </Typography>
					</Box>
					<Box className={classes.shareItem}>
						<LinkedinShareButton title={title} url={socialShareLink}>
							<LinkedinIcon size={40}/>
						</LinkedinShareButton>
						<Typography>Linkedin</Typography>
					</Box>

					<Box className={classes.shareItem}>
						<TwitterShareButton title={title} url={socialShareLink} hashtags={tags} >
							<XIcon size={40} round />
						</TwitterShareButton>
						<Typography>X</Typography>
					</Box>

					<Box className={classes.shareItem}>
						<FacebookShareButton title={title} url={socialShareLink} hashtag={tags[0]} >
							<FacebookIcon size={40} round />
						</FacebookShareButton>
						<Typography>Facebook</Typography>
					</Box>

					<Box className={classes.shareItem}>
						<WhatsappShareButton title={title} url={socialShareLink} >
							<WhatsappIcon size={40} round />
						</WhatsappShareButton>
						<Typography>WhatsApp</Typography>
					</Box>
				</Box>}

			</CenterTile>
			<Confetti width={w} height={h - Number(toolbarHeight.replace("px", ""))} />
		</>
	);
}