export const sideMenuWidth = "400px";
export const toolbarHeight = "80px";

export const palette = {
	background: "#04262f",
	error: "#FE4A49",
	lightGrey: "#d1d1d130",
	darkBlue: "#04262F ",
	turquoise: "#178FA0",
	yellow: "#C7BB42",
	grey: "#3E5B64",
	darkGreen: "#154750"
}