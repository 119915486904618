import {FC, useEffect} from "react";
import {PageBase} from "../../components/pageBase/pageBase";
import {LinearProgress} from "@mui/material";

export const SocialPage: FC = () => {

	useEffect(() => {
		window.location.replace(process.env.SOCIAL_REDIRECT ?? "https://athlyticz.com");
	}, [])

	return (
		<PageBase>
			<LinearProgress />
		</PageBase>
	);

}