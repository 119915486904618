import {Box, BoxProps} from "@mui/material";
import React, {FC, useContext, useEffect, useState} from "react";
import {MobileHeader, NavBar, SideMenu} from "@plumeuk/shapeshift-common/pageBase";
import {makeStyles} from "tss-react/mui";
import {useMq} from "@plumeuk/shapeshift-common/hooks";
import {useLocation, useMatch, useNavigate} from "react-router-dom";
import {sideMenuWidth} from "../../constants";
import {ModuleNavBar} from "../moduleNavBar";
import {AuthContext} from "@plumeuk/shapeshift-identity";
import EditUserDetailsForm from "../editUserDetailsForm/editUserDetailsForm";

const logo = "/logo_large.png"

interface ICustomProps {
	children: React.ReactNode,
	contentWidth?: string,
	sideBarContent?: JSX.Element,
	disableSideBar?: boolean
}

interface IStyleProps {
	sideMenuOpen: boolean,
	hasNavBar: boolean,
	contentWidth?: string
}

const useStyles = makeStyles<IStyleProps>()((theme, {sideMenuOpen, hasNavBar, contentWidth}) => ({
	pageBase: {
		height: "100%"
	},
	background: {
		backgroundColor: theme.palette.background.default,
		top: 0,
		left: 0,
		right: 0,
		bottom: 0,
		zIndex: "-1",
		position: "fixed"
	},
	navBarSpacer: {
		width: "100%",
		height: theme.toolbarHeight
	},
	navBar: {
		"& [ class*='navBarLogo']": {
			"& img": {
				margin: "22px 0",
				height: "60%"
			}
		}
	},
	contentContainer: {
		width: sideMenuOpen ? `calc(100% - ${sideMenuWidth})` : "100%",
		position: "absolute",
		overflowX: "hidden",
		transition: "all .3s ease-in-out",
		left: sideMenuOpen ? sideMenuWidth : "0px",
		boxSizing: "border-box",
		overflowY: "auto",
		padding: "25px",
		top: hasNavBar ? theme.toolbarHeight : 0,
		height: hasNavBar ? `calc(100% - ${theme.toolbarHeight})` : "100%",
		[theme.breakpoints.down("md")]: {
			left: 0,
			width: "100%"
		},
		background: "#f9f9f9"
	},
	sideMenu: {
	},
	content: {
		[theme.breakpoints.up("sm")]:{
			height: "100%"
		},
		minHeight: "100%",
		boxSizing: "border-box",
		width: "100%",
		maxWidth: contentWidth ?? "1000px",
		margin: "auto"

	}
}));

// TEMPORARY REMOVAL OF LIBRARY PAGE
type IProps = ICustomProps & Omit<BoxProps, "children">;
type pages = "My Learning";

type routeCallback = {
	[key in pages]: string;
};

const routesMap: routeCallback = {
	"My Learning": "/my-learning"
}

export const PageBase: FC<IProps> = ({sideBarContent, disableSideBar, contentWidth, children, className, ...props}) => {
	const mq = useMq();
	const {user} = useContext(AuthContext);
	const pageIsMyLearning = useMatch("/my-learning");
	const location = useLocation();
	const [sideMenuOpen, setSideMenuOpen] = useState(!disableSideBar && mq.desktop);
	const {classes, cx} = useStyles({sideMenuOpen, hasNavBar: !mq.mobile || !pageIsMyLearning, contentWidth});
	const navigate = useNavigate();
	const [tabs, setTabs] = useState<pages[]>([]);

	useEffect(() => setSideMenuOpen(!disableSideBar && mq.desktop), [disableSideBar, mq.desktop]);

	useEffect(() => {
		if(!user) return;

		const tabs: pages[] = ["My Learning" /*, "Library" */];

		setTabs(tabs);
	}, [user])

	let pageSelected: pages | undefined = undefined;
	Object.keys(routesMap).forEach(e => {
		const page = e as pages;
		if(location.pathname.startsWith(routesMap[page]))
			pageSelected = page;
	})

	const handlePageSelect = (page: pages): void => {
		navigate(routesMap[page]);
	}

	useEffect(() => {
		if(!disableSideBar && !mq.desktop){
			setSideMenuOpen(false);
		}
	}, [location]);

	const handleContentClick = (): void => {
		if(!mq.desktop && sideMenuOpen) setSideMenuOpen(false);
	}

	return (
		<Box className={cx(classes.pageBase, className)} {...props}>
			{!mq.mobile &&
				<NavBar<pages>
					className={classes.navBar}
					tabs={tabs}
					onBurgerAction={() => setSideMenuOpen(prev => !prev)}
					onPageSelect={handlePageSelect}
					pageSelected={pageSelected}
					logoPath={logo}
					disableSideBar={disableSideBar}
					editUserDetailsForm={EditUserDetailsForm}
				/>}
			{mq.mobile && !pageIsMyLearning &&
				<ModuleNavBar
					onBurgerAction={() => setSideMenuOpen(prev => !prev)}
				/>}
			<SideMenu
				className={classes.sideMenu}
				open={sideMenuOpen}
				width={sideMenuWidth}
			>
				{sideBarContent}
			</SideMenu>
			<Box className={classes.contentContainer} onClick={handleContentClick}>
				{mq.mobile && pageIsMyLearning && <MobileHeader editUserDetailsForm={EditUserDetailsForm} />}
				<Box className={classes.content}>
					{children}
				</Box>
			</Box>
			<Box className={classes.background} />
		</Box>
	);
};
