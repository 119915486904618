import {FC} from "react";
import {NavBar} from "@plumeuk/shapeshift-common/pageBase";
import {makeStyles} from "tss-react/mui";
import MenuIcon from "@mui/icons-material/Menu";
import CloseIcon from "@mui/icons-material/Close";
import {useNavigate} from "react-router-dom";

type IProps = {
	onBurgerAction: () => void,
}

const useStyles = makeStyles()((theme) => ({
	moduleNavBar: {
		backgroundColor: "#fff",
		padding: "0 10px",
		"& svg": {
			color: theme.palette.secondary.main
		}
	},
	burgerIcon: {
		padding: "3px",
		fontSize: "20px",
		borderRadius: "100px",
		border: "2px solid " + theme.palette.secondary.main
	}
}));

export const ModuleNavBar: FC<IProps> = ({onBurgerAction, ...props}) => {
	const {classes} = useStyles();
	const navigate = useNavigate();

	const handleOnClose = ():void => navigate("/my-learning");

	return (
		<NavBar className={classes.moduleNavBar} {...props}>
			<MenuIcon className={classes.burgerIcon} onClick={() => onBurgerAction()}/>
			<CloseIcon onClick={() => handleOnClose()}/>
		</NavBar>
	);
};

export default NavBar;