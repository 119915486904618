import {FC} from "react";
import {Typography} from "@mui/material";
import {CenterTile} from "@plumeuk/shapeshift-common/pageBase";

export const SomethingWentWrongPage: FC = () => {
	return (
		<CenterTile style={{textAlign: "center"}}>
			<Typography variant="h2" padding={3}>Oh no!</Typography>
			<Typography>Something went wrong</Typography>
			<Typography>Please contact your administrator and let them know which course you were trying to access</Typography>
		</CenterTile>
	);
}