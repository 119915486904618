import {VideoPlayer} from "@plumeuk/shapeshift-common/videoPlayer";
import {FC, useState} from "react";
import {makeStyles} from "tss-react/mui";
import {useMq} from "@plumeuk/shapeshift-common/hooks";
import {ILesson} from "@plumeuk/shapeshift-types";

const useStyles = makeStyles()((theme) => ({
	videoPlayerBackdrop: {
		background: "black",
		width: "100%",
		boxSizing: "border-box",
		left: 0,
		top: -10,
		right: "0px",
		position: "absolute",
		height: "550px",
		[theme.breakpoints.down("sm")]: {
			height: "250px"
		}
	},
	videoPlayer: {
		zIndex: 5,
		background: "black",
		position: "absolute",
		opacity: 0,
		left: 0,
		top: -10,
		transition: "opacity .5s ease-in",
		right: "0px"
	},
	videoPlayerSpacer: {
		height: "550px",
		width: "100%",
		[theme.breakpoints.down("sm")]: {
			height: "280px"
		}
	}
}));

interface IProps {
	lesson?: ILesson
}

export const LessonVideoPlayer: FC<IProps> = ({lesson}) => {
	const {classes} = useStyles();
	const mq = useMq()
	const [videoReady, setVideoReady] = useState(false);
	if(!lesson?.videoUrl) return <></>

	return (
		<>
			<VideoPlayer
				onReady={() => setVideoReady(true)}
				className={classes.videoPlayer}
				style={{opacity: videoReady ? 1 : 0}}
				url={lesson.videoUrl}
				controls={true}
				height={mq.mobile ? "250px" : "550px"}
			/>
			<div className={classes.videoPlayerBackdrop}></div>
			<div className={classes.videoPlayerSpacer}/>
		</>
	);
}