import {createTheme, Shadows} from "@mui/material";
import {CustomMuiTheme} from "@plumeuk/shapeshift-common/theming";
import {palette, toolbarHeight} from "../constants";

declare module "@mui/material/styles" {
	interface Theme extends CustomMuiTheme {}
	// allow configuration using `createTheme`
	interface ThemeOptions extends CustomMuiTheme{}
}

export const defaultTheme = createTheme({
	toolbarHeight,
	breakpoints: {
		//Mobile  |		 x < sm
		//Tablet  |	sm < x < md
		//Desktop |		 x > md
		values: {
			// extra-small
			xs: 0,
			// small
			sm: 700,
			// medium
			md: 1000,
			// large
			lg: 1200,
			// extra-large
			xl: 1536
		}
	},
	shadows: Array(25).fill("none") as Shadows,
	palette: {
		background: {
			default: palette.background
		},
		primary: {
			main: palette.turquoise
		},
		secondary: {
			main: palette.darkGreen
		},
		error: {
			main: palette.error
		}
	},
	typography: {
		fontFamily: "Barlow Condensed, sans-serif",
		body1: {
			fontSize: "16px",
			fontFamily: "Karla"
		},
		h1: {
			fontStyle: "normal",
			fontWeight: 700,
			fontSize: "50px",
			lineHeight: "120%"
		},
		h2: {
			fontStyle: "normal",
			fontWeight: 700,
			fontSize: "46px",
			lineHeight: "120%"
		},
		h3: {
			fontStyle: "normal",
			fontWeight: 500,
			fontSize: "22px",
			lineHeight: "30px"
		},
		h4: {
			fontStyle: "normal",
			fontWeight: 700,
			fontSize: "26px",
			lineHeight: "30px"
		},
		body2: {
			fontSize: "11pt"
		},
		h6: {
			fontSize: "20px"
		},
		button: {
			fontSize: "15px",
			textTransform: "none"
		}
	},
	navBar: {
		statusprofile: {
			backgroundColor: palette.turquoise
		}
	},
	components: {
		MuiPaper: {
			styleOverrides: {
				root: {
					boxShadow: "0px 5px 12px 0px rgba(0,0,0,0.08)"
				}
			}
		},
		MuiToolbar: {
			styleOverrides: {
				root: {
					boxShadow: "0px 3px 12px 0px #d1d1d115",
					height: toolbarHeight,
					backgroundColor: palette.darkBlue,
					"& [class*='menuIcon']": {
						fontSize: "1.9rem"
					},
					"& .Mui-selected, & .MuiTab-root": {
						color: "#fff !important"
					},
					"& [class*='bellIconContainer']": {
						display: "none"
					}
				}
			}
		},
		MuiDrawer: {
			styleOverrides: {
				root: {
					"& [class*='paper']": {
						backgroundColor: palette.darkBlue + " !important",
						"& p": {
							color: "white"
						}
					},
					"& [class*='outlineButton']": {
						borderColor: "#fff",
						fontWeight: "700 !important",
						"& svg": {
							display: "none"
						},
						"& p": {
							fontWeight: "700"
						},
						"&:hover": {
							backgroundColor: palette.yellow,
							borderColor: palette.yellow
						}
					}
				}
			}
		},
		MuiLinearProgress: {
			styleOverrides: {
				root: {
					borderRadius: "5px",
					height: "6px",
					backgroundColor: "#fff"
				},
				bar: {
					backgroundColor: palette.turquoise,
					borderRadius: "10px"
				}
			}
		},
		MuiTab: {
			styleOverrides: {
				root: {
					fontFamily: "Barlow Condensed, sans-serif",
					transition: "transform 0.15s ease-in-out",
					"&:hover": {
						transform: "scale(1.04)"
					},
					fontSize: "19px",
					"&.Mui-selected": {
						fontWeight: 700
					}
				}
			}
		},
		MuiTabs: {
			styleOverrides: {
				root: {
					lineHeight: 0
				},
				indicator: {
					display: "none"
				}
			}
		},
		MuiButton: {
			styleOverrides: {
				root: {
					fontSize: "17px",
					backgroundColor: palette.turquoise,
					color: "white",
					fontWeight: 700,
					textTransform: "uppercase",
					padding: "18px 30px",
					borderRadius: "15px",
					transition: "all .15s ease-in-out",
					"&:hover": {
						backgroundColor: palette.yellow,
						transform: "scale(1.05)"
					},
					":disabled": {
						opacity: 0.6,
						color: "white"
					}
				}
			}
		},
		MuiSelect: {
			styleOverrides: {
				standard: {
					"&:focus": {
						backgroundColor: "transparent"
					}
				}
			}
		},
		MuiTextField: {
			styleOverrides: {
				root: {
					backgroundColor: "#fff",
					border: "none",
					padding: "5px 10px",
					borderRadius: "10px"
				}
			}
		},
		MuiTooltip: {
			styleOverrides: {
				tooltip: {
					borderRadius: 100,
					backgroundColor: palette.darkBlue,
					color: "#fff"
				}
			}
		}
	}
});
