
import ReactPDF, {Document, Page, Text, View, StyleSheet, Font, Image} from "@react-pdf/renderer";
import {FC, useCallback, useEffect, useState} from "react";
import {ICourseCertificate} from "@plumeuk/shapeshift-types/ICourse";
import {buildPngFromSvg} from "@plumeuk/shapeshift-common/common";

interface IPropsCustom {
	certificate: ICourseCertificate
}

export type IProps = Omit<ReactPDF.Document["props"], "children"> & IPropsCustom;
const SwavaoUrl = "/Swavao/Swavao.ttf";
const ScriptinaUrl = "/Scriptina/Scriptina.ttf";

Font.register({family: "Swavao", src: SwavaoUrl});
Font.register({family: "Scriptina", src: ScriptinaUrl});

//prevent hyphenation
Font.registerHyphenationCallback(word => {
	return [word];
});

const styles = StyleSheet.create({
	page: {
		fontFamily: "Helvetica",
		flexDirection: "column",
		backgroundColor: "#ffffff",
		fontSize: 12
	},
	section: {
		justifyContent: "space-around",
		margin: 10,
		padding: 10,
		flexGrow: 1,
		lineHeight: 1.5
	},
	topSection: {
		color: "#178FA0",
		textAlign: "left",
		position: "absolute",
		top: "115px",
		height: "120px",
		overflowY: "hidden",
		right: "100px",
		width: "280px"
	},
	printedName: {
		fontFamily: "Scriptina",
		fontSize: 35
	},
	h2: {
		fontSize: 20
	},
	institution: {
		paddingTop: 20,
		color: "#d1d1d1",
		fontFamily: "Helvetica-Bold"
	},
	backgroundImg:{
		position: "absolute",
		top: 0,
		left: 0,
		right: 0,
		bottom: 0,
		width: "100%",
		height: "100%",
		objectFit: "cover"
	},
	instructorContainer: {
		position: "absolute",
		borderBottom: "4px solid black",
		display: "flex",
		justifyContent: "flex-end",
		bottom: "205px",
		width: "365px",
		height: "160px",
		textAlign: "right",
		right: "112px"
	},
	instructor:{
		color: "#178FA0",
		fontSize: "45px",
		fontFamily: "Scriptina",
		position: "relative",
		top: "32px"
	},
	bottomSection: {
		flexDirection: "row",
		color: "white"
	},
	linedSection: {
		flexGrow: 1
	},
	linedSectionValueContainer: {
		borderBottom: "1px solid white"
	},
	linedSectionLabelContainer: {
	},
	spacer: {
		width: 20
	},
	courseTitleContainer: {
		position: "absolute",
		top: "180px",
		textOverflow: "ellipsis",
		whiteSpace: "nowrap",
		width: "73%",
		overflow: "hidden",
		height: "135px",
		padding: "3px",
		fontSize: "20px",
		right: "91px"

	},
	courseTitle: {
		fontFamily: "Swavao",
		textOverflow: "ellipsis",
		lineHeight: 1.2,
		whiteSpace: "nowrap",
		overflow: "hidden",
		hyphens: "none"
	},
	courseTitleCompletedOnContainer: {
		position: "absolute",
		top: "248px",
		textOverflow: "ellipsis",
		whiteSpace: "nowrap",
		width: "130px",
		overflow: "hidden",
		height: "35px",
		padding: 0,
		fontSize: "20px",
		left: "265px",
		border: "1px solid red"
	},
	completedOn: {
		fontFamily: "Swavao"
	},
	certificateLableTxtContainer: {
		position: "absolute",
		borderRight: "6px solid black",
		paddingRight: "10px",
		paddingTop: "20px",
		height: "110px",
		top: "95px",
		left: "110px"
	},
	certificateLableTxt: {
		fontFamily: "Swavao",
		fontSize: "45px"
	},
	ofCompletionTxtContainer: {
		position: "absolute",
		top: "160px",
		left: "130px"
	},
	ofCompletionTxt: {
		fontFamily: "Swavao",
		fontSize: "30px"
	},
	proudlyPresentedToTxtContainer: {
		position: "absolute",
		top: "120px",
		right: "190px"
	},
	proudlyPresentedToTxt: {
		fontFamily: "Swavao",
		fontSize: "15px"
	},
	instructorTagLineContainer: {
		position: "absolute",
		display: "flex",
		justifyContent: "flex-end",
		bottom: "165px",
		width: "365px",
		height: "60px",
		textAlign: "right",
		right: "112px"
	},
	instructorTagLine: {
		maxHeight: "40px",
		fontFamily: "Swavao",
		fontSize: "20px"
	}
});

export const CourseCertificateReactPDFDocument: FC<IProps> = ({certificate, ...props}) => {

	const [imgSrc, setImgSrc] = useState<string>("");

	const svgToPng = useCallback(async (url: string) => {
		const mSrc = await buildPngFromSvg(url)
		setImgSrc(mSrc);
	}, []);


	useEffect(() => {
		const isSrcSvg = certificate?.backgroundUrl && certificate?.backgroundUrl?.endsWith(".svg");
		if (isSrcSvg) {
			svgToPng(certificate.backgroundUrl ?? "");
		}
	}, [certificate]);

	return <Document {...props}>
		<Page size={"LETTER"} orientation="landscape" style={styles.page}>
			<Image style={styles.backgroundImg} src={!imgSrc ? certificate.backgroundUrl : imgSrc} />
			<View style={styles.section}>
				<Text style={[styles.h2, styles.institution]}>{certificate.institution}</Text>
			</View>

			<View style={[styles.certificateLableTxtContainer]}>
				<Text style={styles.certificateLableTxt}>Certificate</Text>
			</View>
			<View style={[styles.ofCompletionTxtContainer]}>
				<Text style={styles.ofCompletionTxt}>of Completion</Text>
			</View>
			<View style={[styles.proudlyPresentedToTxtContainer]}>
				<Text style={styles.proudlyPresentedToTxt}>proudly presented to</Text>
			</View>


			<View style={[styles.topSection]}>
				<Text style={styles.printedName}>{certificate.printedName}</Text>
			</View>

			<View style={[styles.section, styles.courseTitleContainer]}>
				<Text style={styles.courseTitle}>
					FOR THEIR COMPLETION OF {certificate.printedTitle?.toLocaleUpperCase()} COMPLETED ON {certificate.completionDate.toLocaleDateString()} WITH ATHLYTICZ.ACADEMY
				</Text>
			</View>


			{certificate.instructor && <>
				<View style={styles.instructorContainer}>
					<Text style={styles.instructor}>{certificate.instructor}</Text>
				</View>
				<View style={styles.instructorTagLineContainer}>
					<Text style={styles.instructorTagLine}>{(certificate as any).certificateOverridePrintedName ?? certificate.instructor}</Text>
				</View>
			</>}

			{/* {certificate.expiryDate && <View style={styles.linedSection}>
					<View style={styles.linedSectionValueContainer}>
						<Text style={styles.linedSectionValue}>{certificate.expiryDate.toDateString()}</Text>
					</View>
					<View style={styles.linedSectionLabelContainer}>
						<Text style={styles.linedSectionLabel}>Expiry date</Text>
					</View>
				</View>} */}

		</Page>
	</Document>
};
