import {Box} from "@mui/material";
import {FC, useContext, useEffect} from "react";
import {useNavigate, useSearchParams} from "react-router-dom";
import {AuthContext} from "@plumeuk/shapeshift-identity";
import {LoginForm} from "@plumeuk/shapeshift-common/login";
import {makeStyles} from "tss-react/mui";

const logo = "/logo_large.png"

const useStyles = makeStyles()((theme) => ({
	loginPage:{
		backgroundColor: theme.palette.background.default,
		minHeight: "100%",
		width: "100%",
		boxSizing: "border-box",
		display: "flex",
		"& [ class*='MuiInput']:before": {
			border: "none!important"
		},
		alignItems: "center",
		flexDirection: "column"
	},
	container: {
		marginTop: "15px",
		margin: "auto",
		display: "flex",
		flexDirection: "column",
		[theme.breakpoints.down("sm")]: {
			flexDirection: "column-reverse"
		}
	},
	imgContainer: {
		margin: "40px 0",
		textAlign: "center",
		width: "100%",
		[theme.breakpoints.down("sm")]: {
			margin: "30px 0"
		},
		"& img": {
			width: "150px",
			[theme.breakpoints.down("sm")]: {
				width: "149px"
			}
		}
	},
	loginForm: {
		marginTop: "15px"
	}
}));

export const LoginPage: FC = () => {
	const {classes} = useStyles();
	const {authenticated} = useContext(AuthContext);
	const [searchParams] = useSearchParams();
	const navigate = useNavigate();

	useEffect(() => {
		if(!authenticated)
			return;
		const redirectPath = searchParams.get("redirectPath");
		const redirectSearch = searchParams.get("redirectSearch");

		navigate(redirectPath
			? {
				pathname: redirectPath,
				search: redirectSearch ?? ""
			} : "/my-learning");

	}, [authenticated]);

	return (
		<Box className={classes.loginPage}>
			<Box className={classes.container}>
				<LoginForm
					className={classes.loginForm}
					title="Welcome to AthlyticZ"
					onLogin={() => {}}
					onForgotPasswordAction={() => navigate("forgotten-password")}
				/>
				<div className={classes.imgContainer}>
					<img src={logo} />
				</div>
			</Box>
		</Box>
	);
}