import {Box, Button, Typography} from "@mui/material";
import {CenterTile} from "@plumeuk/shapeshift-common/pageBase";
import {OutlineButton} from "@plumeuk/shapeshift-common/buttons";
import {FC, useContext, useEffect} from "react";
import {makeStyles} from "tss-react/mui";
import {useNavigate, useParams} from "react-router-dom";
import WhatshotOutlinedIcon from "@mui/icons-material/WhatshotOutlined";
import {CurriculumContext} from "../../../../../contexts/curriculumContext";
import {IMarkedQuizAnswers, IQuiz} from "@plumeuk/shapeshift-types";

const useStyles = makeStyles()((theme) => ({
	quizResultSummary: {
		textAlign: "center"
	},
	btn: {
		[theme.breakpoints.up("sm")]: {
			width: "170px",
			height: "52px"
		}
	},
	content: {
		fontSize: "20px",
		lineHeight: "34px",
		margin: "40px 0",
		[theme.breakpoints.down("sm")]: {
			lineHeight: "25px",
			fontSize: "18px"
		}
	}
}));

interface IProps {
	quiz?: IQuiz,
	markedAnswers: IMarkedQuizAnswers,
	onCheckAnswersAction: () => void
}

export const QuizResultSummary: FC<IProps> = ({quiz, markedAnswers, onCheckAnswersAction}) => {
	const {classes} = useStyles();
	const {courseSlug} = useParams();
	const totalQuestions = (markedAnswers.correct + markedAnswers.incorrect);
	const {curriculumDispatch} = useContext(CurriculumContext);
	const accuracy = Math.round((markedAnswers.correct / totalQuestions) * 100);
	const navigate = useNavigate();

	useEffect(() => {
		if(!markedAnswers) return;
		if(!markedAnswers.pass){
			onCheckAnswersAction();
		}
		else {
			curriculumDispatch({
				type: "setModuleComplete", module: {type: "quiz", slug: quiz?.slug as string}, status: true})
		}
	}, [markedAnswers, onCheckAnswersAction])

	const nextModuleBtn = <Button
		onClick={() => navigate("/course/" + courseSlug)}
		className={classes.btn}
	>
		Next module
	</Button>

	const retakeBtn = <OutlineButton
		onClick={() => navigate("../active")}
		className={classes.btn}
	>
		Retake quiz
	</OutlineButton>

	const checkAnswersBtn = <OutlineButton
		onClick={onCheckAnswersAction}
		className={classes.btn}
	>
		Check answers
	</OutlineButton>

	return (
		<Box className={classes.quizResultSummary}>
			<CenterTile
				icon={<WhatshotOutlinedIcon />}
				buttons={[nextModuleBtn, checkAnswersBtn, ...((accuracy < 100) ? [retakeBtn] : [])]}
				title={`You passed ${quiz?.title}${quiz?.title.charAt(quiz?.title.length-1) === "!" ? "" : "!"}`}
			>
				<Typography className={classes.content}>
					You scored {accuracy}% which is a pass. {(accuracy < 100) && <br/>}
					You can proceed to the next module{accuracy < 100 ? "," : " or"} review you answers
					{accuracy < 100 && " or retake the quiz"}.
				</Typography>
			</CenterTile>
		</Box>
	);
}