import {FC, useCallback, useContext, useState} from "react";
import {useNavigate, useParams} from "react-router-dom";
import {Box, Typography} from "@mui/material";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import {makeStyles} from "tss-react/mui";
import {ModuleCompleteButton, ModuleNotAvailable, ModuleTabs, ModuleTab, ModuleUnCompleteButton} from "@plumeuk/shapeshift-common/module";
import {PageTitle} from "@plumeuk/shapeshift-common/pageBase";
import {NotesSection} from "@plumeuk/shapeshift-common/notes";
import {Files} from "@plumeuk/shapeshift-common/files";
import {CommentsSection} from "@plumeuk/shapeshift-common/comments";
import {Links} from "@plumeuk/shapeshift-common/links";
import {INotificationSeverity, APIState, NotificationContext} from "@plumeuk/shapeshift-identity";
import {CenteredContainer} from "../../../components/pageBase/centeredContainer";
import {CurriculumContext} from "../../../contexts/curriculumContext";
import {LessonVideoPlayer} from "./LessonVideoPlayer";
import {LessonDataProvider} from "@plumeuk/shapeshift-common/providers";
import {IModuleEnrollment} from "@plumeuk/shapeshift-common/types";
import {palette} from "../../../constants";
import {VirtualMachine} from "../../../components/virtualMachine/virtualMachine"
import {WYSIWYG} from "@plumeuk/shapeshift-common/wysiwyg"

const useStyles = makeStyles()((theme) => ({
	lessonPage: {
		"& [class*='loomVid-videoPlayer']": {
			maxHeight: "550px !important",
			minHeight: "550px !important",
			height: "550px !important"
		},
		paddingTop: "50px",
		[theme.breakpoints.down("sm")]: {
			paddingTop: "20px"
		}
	},
	title:{
		marginBottom: "40px",
		[theme.breakpoints.down("sm")]: {
			marginBottom: "30px"
		},
		"& h5": {
			lineHeight: 0.5
		}
	},
	centeredContainer: {
		paddingBottom: "100px",
		maxWidth: "900px"
	},
	lessonCompleteBtn: {
		float: "right"
	},
	content: {
		marginBottom: "40px",
		"& p": {
			lineHeight: 1.5,
			fontSize: "18px"
		}
	},
	contentImage: {
		maxWidth: "100%"
	},
	fileTitle: {
		fontWeight: 700,
		margin: "20px 0"
	},
	files: {
		marginBottom: "40px",
		"& [class*='contentContainer']": {
			margin: "8px 30px 10px 30px",
			"& h6": {
				lineHeight: 1.3
			},
			"& p": {
				fontFamily: "Barlow Condensed"
			}
		}
	},
	nextBtn: {
		position: "absolute",
		[theme.breakpoints.down("sm")]: {
			margin: 0,
			borderRadius: "0",
			left: 0,
			top: "240px",
			padding: "14px 0",
			right: "0px",
			"& svg": {
				fontSize: "20px"
			}
		},
		[theme.breakpoints.up("sm")]: {
			zIndex: 10,
			background: "#fff",
			color: theme.palette.secondary.main,
			right: "-160px",
			fontSize: "22px",
			opacity: 0.7,
			top: "200px",
			borderRadius: 100,
			padding: "22px 150px 22px 22px",
			transition: "all 0.2s ease-in-out",
			"&:hover": {
				opacity: 1,
				right: "-140px",
				color: theme.palette.secondary.main,
				background: "#fff"
			},
			"& span": {
				display: "none"
			},
			"& svg": {
				fontSize: "48px"
			}
		}
	},
	tabs: {
		marginBottom: "30px",
		borderBottom: palette.lightGrey + " 3px solid"
	},
	links: {
		marginBottom: "40px"
	},
	tab: {
		color: "#A8A8A8",
		fontSize: "18px"
	},
	activeTab: {
		color: "#2A2A2F !important"
	}
}));

const MODULE_TABS: {label: string, value: ModuleTab}[] = [
	{
		label: "Overview",
		value: "overview"
	},
	{
		label: "Notes",
		value: "notes"
	}
]


export const LessonPage: FC = () => {
	const {classes} = useStyles();
	const {courseSlug} = useParams();
	const {moduleSlug: lessonSlug} = useParams();
	const {curriculumState: curriculum, curriculumDispatch} = useContext(CurriculumContext);
	const {notify} = useContext(NotificationContext);
	const navigate = useNavigate();
	const [tabSelected, setTab] = useState<ModuleTab>("overview");

	const handleCompletion = useCallback((e: APIState<IModuleEnrollment>): void => {
		if(e.isError) {
			notify("Please try again", "Something went wrong", INotificationSeverity.error, 5000);
		}
		else if(!e.isLoading && !e.isError && e.statusCode === 200){
			const status = !!e?.data?.complete;
			curriculumDispatch({
				type: "setModuleComplete", module: {type: "lesson", slug: lessonSlug as string}, status})

			if(e?.data?.complete){
				notify(null, "Lesson completed", INotificationSeverity.success, 5000);
				navigate("/course/" + courseSlug)
			}
			else {
				notify("Set to incomplete", "Lesson Updated", INotificationSeverity.success, 5000);
			}
		}
	}, [curriculumDispatch, lessonSlug])

	return (
		<Box className={classes.lessonPage}>
			<LessonDataProvider courseSlug={courseSlug} lessonSlug={lessonSlug} curriculum={curriculum}>
				{({lesson, apiResult}) => <>
					{apiResult.statusCode === 200 && <>
						{lesson?.videoUrl && <>
							<LessonVideoPlayer lesson={lesson}/>
							{courseSlug && <ModuleCompleteButton
								courseSlug={courseSlug}
								moduleSlug={lesson.slug}
								type="lesson"
								className={classes.nextBtn}
								onApiUpdate={handleCompletion}
							>
								{() => <><span>Next Lesson</span> <NavigateNextIcon/></>}
							</ModuleCompleteButton>}

						</>}
						<CenteredContainer className={classes.centeredContainer}>
							<ModuleTabs moduleTabs={MODULE_TABS} tabSelected={tabSelected} onChange={tab => setTab(tab)} className={classes.tabs} />
							{tabSelected === "overview" && <>
								<PageTitle className={classes.title} title={lesson?.title} subtitle={lesson?.subtitle} />
								{(lesson as any)?.topContent && <WYSIWYG className={classes.content}>
									{(lesson as any)?.topContent ?? ""}
								</WYSIWYG>}
								{(lesson as any)?.virtualMachine && <VirtualMachine courseSlug={courseSlug} />}
								<WYSIWYG className={classes.content}>
									{lesson?.content ?? ""}
								</WYSIWYG>

								{lesson?.files && <>
									<Typography className={classes.fileTitle} variant="h5">Files</Typography>
									<Files className={classes.files} files={lesson.files}/>
								</>}

								{lesson?.links && lesson.links.length > 0 && <>
									<Typography className={classes.fileTitle} variant="h5">Links</Typography>
									<Links className={classes.links} links={lesson.links}/>
								</>}

								{courseSlug && lesson?.slug && !lesson?.complete &&
									<ModuleCompleteButton
										courseSlug={courseSlug}
										moduleSlug={lesson.slug}
										type="lesson"
										className={classes.lessonCompleteBtn}
										onApiUpdate={handleCompletion}
									/>
								}
								{courseSlug && lesson?.slug && lesson?.complete &&
									<ModuleUnCompleteButton
										courseSlug={courseSlug}
										moduleSlug={lesson.slug}
										type="lesson"
										className={classes.lessonCompleteBtn}
										onApiUpdate={handleCompletion}
									/>
								}
							</>}
							{tabSelected === "comments" && <CommentsSection courseSlug={courseSlug} moduleType={"lesson"} moduleSlug={lessonSlug}/>}
							{tabSelected === "notes" && <NotesSection courseSlug={courseSlug} moduleType="lesson" moduleSlug={lessonSlug} />}
						</CenteredContainer>
					</>}
					{apiResult.statusCode === 403 &&
						<ModuleNotAvailable>
							{apiResult.errorData?.error.message}. We&apos;ll email you when it&apos;s available
						</ModuleNotAvailable>
					}
				</>}
			</LessonDataProvider>
		</Box>
	);
}