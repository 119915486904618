import {MarkQuizActionProvider, QuizQuestionsDataProvider} from "@plumeuk/shapeshift-common/providers";
import {FC} from "react";
import {useParams} from "react-router-dom";
import {makeStyles} from "tss-react/mui";
import {CenteredContainer} from "../../../../components/pageBase/centeredContainer";
import {QuizQuestionContainer} from "./QuizQuestion/QuizQuestionContainer";

const useStyles = makeStyles()((theme) => ({
	centeredContainer: {
		display: "flex",
		flexDirection: "column",
		justifyContent: "center",
		textAlign: "center"
	},
	centerTileContainer: {
		width: "100%",
		maxWidth: "740px",
		margin: "auto",
		boxSizing: "border-box"
	},
	centerTile: {
		position: "relative",
		borderRadius: "10px",
		boxSizing: "border-box",
		background: "#fff",
		width: "100%",
		padding: "70px",
		[theme.breakpoints.down("sm")]: {
			padding: "60px 25px 40px 25px"
		}
	},
	title: {
		marginBottom: "40px",
		[theme.breakpoints.down("sm")]: {
			marginBottom: "20px",
			"& h2": {
				fontSize: "30px"
			},
			"& h5": {
				fontSize: "20px"
			}
		}
	},
	continueBtn: {
		marginTop: "30px",
		width: "127px",
		height: "52px",
		[theme.breakpoints.down("sm")]: {
			width: "100%"
		}
	},
	content: {
	},
	contentImage: {
		maxWidth: "100%"
	},
	flashIcon: {
		marginLeft: "-30px",
		backgroundColor: theme.palette.secondary.main,
		color:  theme.palette.secondary.main,
		strokeWidth: "2px",
		stroke: "#fff",
		fontSize: "30px",
		padding: "15px",
		borderRadius: "18px",
		position: "absolute",
		top: "-30px"
	}
}));

export const QuizQuestions: FC = () => {
	const {classes} = useStyles();
	const {courseSlug, moduleSlug: quizSlug} = useParams();

	return (
		<CenteredContainer className={classes.centeredContainer}>
			<MarkQuizActionProvider>
				{({markAnswers, response}) =>
					<QuizQuestionsDataProvider courseSlug={courseSlug} quizSlug={quizSlug}>
						{({quizQuestions}) =>
							<QuizQuestionContainer
								questions={quizQuestions}
								onSubmit={(answers) => markAnswers(courseSlug as string, quizSlug as string, answers)}
								submitResponseResultId={response}
							/>
						}
					</QuizQuestionsDataProvider>
				}
			</MarkQuizActionProvider>
		</CenteredContainer>
	);
}